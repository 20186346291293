"use strict";

import wrapper from "@/api/wrapper/axios-wrapper.js";
import {getAuthTokenHeader} from "@/utils/auth.js";

export const SOLUTION_API = {
  find(keyword) {
    return wrapper.get("/v1/solution", {name: keyword});
  },
  link(userId, solutionId, solutionUserId, apikey, clientKey) {
    return wrapper.post(
      "/v1/user/" + userId + "/solution",
      {
        solutionId: solutionId,
        solutionUserId: solutionUserId,
        apikey: apikey,
        clientKey: clientKey
      },
      {
        headers: getAuthTokenHeader()
      }
    );
  }
};

export default SOLUTION_API;

"use strict";

import wrapper from "@/api/wrapper/axios-wrapper.js";

export const AUTH_API = {
  getTemporalKey() {
    return wrapper.post("/external/mysoho/tempkey", {}, {});
  },

  delTemporalKey(tempkey) {
    return wrapper.delete("/external/mysoho/tempkey/" + tempkey, {});
  },

  decrypt(data, date) {
    return wrapper.post(
      "/external/mysoho/decrypt",
      {data: data, date: date},
      {}
    );
  }
};

export default AUTH_API;

"use strict";

import wrapper from "@/api/wrapper/axios-wrapper.js";

export const TERMS_API = {
  currentTermsOfUse(payload) {
    return wrapper.get(`/v1/terms-of-use/${payload.termsId}`, {
      service: "qpick",
      type: "termsOfUse"
    });
  },
  currentPrivacyPolicy(payload) {
    return wrapper.get(`/v1/terms-of-use/${payload.termsId}`, {
      service: "qpick",
      type: "privacyPolicy"
    });
  },
  allTerms(payload) {
    return wrapper.get(`/v1/terms-of-use`, {
      service: "qpick",
      type: payload.type,
      sortBy: payload.sortBy
    });
  }
};

export default TERMS_API;

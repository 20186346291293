var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "material-textfield"
  }, [_vm.type === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value,
      expression: "value"
    }],
    staticClass: "material-textfield__input",
    attrs: {
      "placeholder": " ",
      "maxlength": _vm.maxlength,
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.value) ? _vm._i(_vm.value, null) > -1 : _vm.value
    },
    on: {
      "input": _vm.update,
      "blur": _vm.validate,
      "change": function change($event) {
        var $$a = _vm.value,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.value = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.value = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.value = $$c;
        }
      }
    }
  }) : _vm.type === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value,
      expression: "value"
    }],
    staticClass: "material-textfield__input",
    attrs: {
      "placeholder": " ",
      "maxlength": _vm.maxlength,
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.value, null)
    },
    on: {
      "input": _vm.update,
      "blur": _vm.validate,
      "change": function change($event) {
        _vm.value = null;
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value,
      expression: "value"
    }],
    staticClass: "material-textfield__input",
    attrs: {
      "placeholder": " ",
      "maxlength": _vm.maxlength,
      "type": _vm.type
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.value = $event.target.value;
      }, _vm.update],
      "blur": _vm.validate
    }
  }), _c('label', {
    staticClass: "material-textfield__label"
  }, [_vm._v(_vm._s(_vm.placeholder))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
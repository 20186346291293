<template>
  <div class="wrapper">
    <div class="signup inner-wrapper">
      <div class="accounts-loading" v-if="mode == 'loading'">
        <Spinner></Spinner>
      </div>
      <div class="interlock-form accounts-form" v-if="mode != 'loading'">
        <!-- Entrance point -->
        <div class="interlock-entrance" v-if="mode == 'entrance'">
          <h1
            class="accounts-form__bi-header bi-header cursor"
            @click="openQpick"
          >
            마이소호 연동
          </h1>
          <div class="description">큐픽에 가입한 계정이 있나요?</div>
          <button class="button button-yes" @click="showRegular">
            예, 회원계정이 있습니다.
          </button>
          <button class="button button-no" @click="showAssociate">
            아니오, 준회원으로 먼저 이용하겠습니다.
          </button>
          <div class="gray-description">
            회원으로 이용하면 더 많은 기능을 사용할 수 있습니다.<br />
          </div>
          <div class="link__highlighted">
            <a v-on:click.prevent="sendToRegister">초간편 회원가입하기</a>
          </div>
        </div>

        <!-- Login form as a regular user -->
        <div class="interlock-form__login" v-if="mode == 'regular'">
          <h1 class="accounts-form__small-bi-header cursor" @click="openQpick">
            Qpick
          </h1>
          <h1 class="accounts-form__header">회원인증</h1>
          <outlinedTextField
            placeholder="아이디(이메일)"
            @value="setEmail"
            class="accounts-form__outlined-text"
            type="text"
            case="email"
            @validate="validate"
          ></outlinedTextField>
          <p class="accounts-form__form-error" v-if="error.email">
            {{ error.email }}
          </p>
          <outlinedTextField
            placeholder="비밀번호"
            @value="setPassword"
            class="accounts-form__outlined-text"
            type="password"
            case="password"
            @validate="validate"
          ></outlinedTextField>
          <p class="accounts-form__form-error" v-if="error.password">
            {{ error.password }}
          </p>
          <button
            class="login--submit accounts-form__submit"
            type="submit"
            @click="validateAll"
          >
            회원인증
          </button>
          <button
            class="accounts-footer__button-transparent"
            @click="showEntrance"
          >
            돌아가기
          </button>
        </div>

        <!-- Login form as a regular user -->
        <div class="interlock-form__associate" v-if="mode == 'associate'">
          <h1 class="accounts-form__small-bi-header cursor" @click="openQpick">
            Qpick
          </h1>
          <h1 class="accounts-form__header">이용약관</h1>
          <div class="interlock-form__agreements">
            <input
              type="checkbox"
              class="interlock-form__agreements--checkbox"
              v-model="checkedAgreeTerms"
            />
            <label
              class="interlock-form__agreements--label"
              @click="checkedAgreeTerms = !checkedAgreeTerms"
              >이용약관 동의</label
            >
            <p class="accounts-form__form-error" v-if="error.terms">
              {{ error.terms }}
            </p>
          </div>
          <div class="interlock-form__terms-wrap">
            <perfect-scrollbar class="interlock-form__terms-scroll">
              <div
                class="interlock-terms__contents"
                v-html="contentsTerms"
              ></div>
            </perfect-scrollbar>
          </div>
          <button class="accounts-form__submit" @click="doneAssociate">
            준회원 연동하기
          </button>
          <button
            class="accounts-footer__button-transparent"
            @click="showEntrance"
          >
            돌아가기
          </button>
        </div>
      </div>
    </div>
    <toastMsg ref="toast"></toastMsg>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import authAPI from "@/api/accounts/auth.js";
import mysohoAPI from "@/api/external/mysoho.js";
import termsAPI from "@/api/accounts/terms.js";
import userAPI from "@/api/accounts/user.js";
import solutionAPI from "@/api/solution.js";
import {setAuthToken} from "@/utils/auth.js";
import Spinner from "@/components/basis/spinner.vue";
import outlinedTextField from "@/components/accounts/outlined-text-field.vue";
import toastMsg from "@/components/accounts/toast-message.vue";
import {PerfectScrollbar} from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import "@/assets/scss/accounts/basis.scss";

const ModuleAuth = createNamespacedHelpers("ModuleAuth");

export default {
  data() {
    return {
      tempkey: "",
      mode: "loading",
      solutionUserId: "",
      apikey: "",
      clientkey: "",
      username: "",
      password: "",
      contentsTerms: "",
      checkedEmail: false,
      checkedPassword: false,
      checkedAgreeTerms: false,
      error: {email: "", password: "", terms: ""}
    };
  },
  methods: {
    openQpick() {
      window.open("/introduction/main", "_blank");
    },
    showEntrance() {
      this.mode = "entrance";
    },
    showRegular() {
      this.mode = "regular";
    },
    showAssociate() {
      this.mode = "loading";
      let payload = {};
      payload.termsId = "current";

      termsAPI.currentTermsOfUse(payload).then(res => {
        this.contentsTerms = res.data.contents["ko"];
        this.mode = "associate";
      });
    },
    //input 유효성 검사
    validate(obj) {
      const _case = obj.case;

      if (_case === "email") {
        if (!this.username) {
          this.error.email = "아이디를 입력해주세요.";
          this.checkedEmail = false;
        } else if (
          !this.username.match(
            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
          )
        ) {
          this.error.email = "이메일 형식이 맞지 않습니다.";
          this.checkedEmail = false;
        } else {
          this.error.email = "";
          this.checkedEmail = true;
        }
      }

      if (_case === "password") {
        if (!this.password) {
          this.error.password = "비밀번호를 입력해주세요.";
          this.checkedPassword = false;
        } else {
          this.error.password = "";
          this.checkedPassword = true;
        }
      }
    },
    //회원인증 버튼 눌렀을때 모든 입력창 유효성 검사
    validateAll() {
      if (!this.username || !this.password) return false;

      const inputs = [
        {case: "email", value: this.username},
        {case: "password", value: this.password}
      ];

      inputs.forEach(item => {
        this.validate(item);
      });

      //모든 필수값 유효성 검사 성공시
      if (this.checkedEmail && this.checkedPassword) this.doneRegular();
    },
    sendToRegister() {
      window.open("/accounts/signup", "_blank", "width=757,height=1012");
      /*
      // 가입화면 이동을 위한 임시키 재발급
      mysohoAPI.getTemporalKey().then(res => {
        if (res.status == 201) {
          // Session storage에 임시키 저장
          const tempkey = res.data.tempKey;
          sessionStorage.setItem("mysoho_temporal_key", tempkey);

          // 가입화면 이동
          this.$router.push({
            path: "/accounts/signup",
            query: {
              callback_url: this.$route.fullPath,
              callback_name: "마이소호 연동 계속하기"
            }
          });
        }
      });
      */
    },
    doneRegular() {
      let payload = {};
      payload.username = this.username;
      payload.password = this.password;

      this.actLogin(payload).then(response => {
        switch (response.status) {
          case 200:
            setAuthToken(response.data.accessToken);

            solutionAPI.find("마이소호").then(solution => {
              authAPI.logged().then(logged => {
                solutionAPI
                  .link(
                    logged.data.id,
                    solution.data[0].id,
                    this.solutionUserId,
                    this.apikey,
                    this.clientkey
                  )
                  .then(() => {
                    this.complete();
                  });
              });
            });
            break;

          case 404:
            this.$refs.toast.show("가입되어있는 아이디가 아닙니다.");
            break;

          case 422:
            if (response.data.errors[0].code == "validation.email") {
              this.$refs.toast.show("이메일 형식이 맞지 않습니다.");
            } else {
              this.$refs.toast.show("비밀번호가 맞지 않습니다.");
            }
            break;

          default:
            alert(response.data.errors[0].userMsg);
            break;
        }
      });
    },
    doneAssociate() {
      if (!this.checkedAgreeTerms) {
        this.$refs.toast.show("이용약관에 동의해주세요.");
        return false;
      }

      solutionAPI.find("마이소호").then(solution => {
        userAPI
          .create_associate(
            solution.data[0].id,
            this.solutionUserId,
            this.apikey,
            this.clientkey
          )
          .then(() => {
            this.complete();
          });
      });
    },
    setEmail(v) {
      this.username = v;
    },
    setPassword(v) {
      this.password = v;
    },
    complete() {
      this.$router.push({
        name: "mysoho-interlock-complete",
        params: {
          success: true,
          msg: "연동성공",
          apikey: this.apikey,
          shopid: this.solutionUserId
        }
      });
    },
    ...ModuleAuth.mapActions(["actLogin"])
  },
  components: {
    Spinner,
    PerfectScrollbar,
    outlinedTextField,
    toastMsg
  },
  created() {
    // 주요 인자 보관
    this.tempkey = sessionStorage.getItem("mysoho_temporal_key");
    this.solutionUserId = this.$route.query.shop_id;
    this.apikey = this.$route.query.api_key;
    this.clientkey = this.$route.query.client_key;
  },
  mounted() {
    // 임시키 제거
    setTimeout(() => {
      mysohoAPI.delTemporalKey(this.tempkey).then(res => {
        if (res.status != 204) {
          this.$router.replace("/mysoho/interlock");
        } else {
          console.log(res.status);
          this.showEntrance();
          sessionStorage.removeItem("mysoho_temporal_key");
        }
      });
    }, 3000);
  }
};
</script>

<style lang="scss">
.accounts-form__header {
  margin-bottom: 100px;
}

.interlock-entrance {
  .bi-header {
    margin-bottom: 0;
  }
  .description {
    height: 16px;
    margin-top: 100px;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
  }
  .button {
    width: 100%;
    max-width: 380px;
    height: 56px;
    left: 450px;
    top: 400px;
    margin-bottom: 24px;
    border-radius: 8px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.005em;
  }
  .button-yes {
    background: #54c7a2;
  }
  .button-no {
    background: #985fe8;
  }
  .gray-description {
    padding: 16px 0 11px 0;
    color: #8d8d8d;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.005em;
    line-height: 14px;
    text-align: center;
  }
  .link__highlighted {
    height: 14px;
    text-align: center;
  }
  .link__highlighted a {
    color: #309574;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.005em;
    text-decoration-line: underline;
    line-height: 14px;
    cursor: pointer;
  }
  .link__highlighted a:after {
    content: ">";
  }
}

.interlock-form__login {
  .accounts-footer__button-transparent {
    margin-top: 16px;
  }
}

.interlock-form__associate {
  height: calc(960px - 89px);

  .interlock-form__agreements {
    height: 30px;
    margin-bottom: 16px;
  }
  .interlock-form__agreements--checkbox {
    position: relative;
    top: 2px;
    left: 0;
    visibility: hidden;
    margin-right: -22px;
  }
  .interlock-form__agreements--checkbox
    ~ .interlock-form__agreements--label::before {
    position: relative;
    top: 3px;
    display: inline-block;
    content: "\2714";
    width: 30px;
    height: 30px;
    margin-right: 8px;
    border: 2px solid #d8dae5;
    border-radius: 50%;
    box-sizing: border-box;
    color: #d8dae5;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
  }
  .interlock-form__agreements--checkbox:checked
    ~ .interlock-form__agreements--label::before {
    background-color: #54c7a2;
    border: 2px solid #54c7a2;
    color: #ffffff;
  }
  .interlock-form__terms-wrap {
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
  }
  .interlock-form__terms-scroll {
    height: 324px;
  }
  .interlock-terms__contents {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    color: #484948;
  }
  .accounts-form__submit {
    margin-top: 24px;
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 720px) {
  /*
  .accounts-terms__contents {
    height: 290px;
  }
  */
  .interlock-form__associate {
    padding-top: 89px;
  }
}
@media screen and (max-width: 719px) {
  /*
  .accounts-terms__contents {
    height: calc(100vh - 442px);
  }
  */
}
</style>
